
import { Emitter } from "./eventEmiter";


export default class servicConnect {
    constructor(props) {
        this.ConnectionEstd = "onConnectionEstablished";
        this.OnReceivingData = "OnReceivingData";
        this.ConnectionClosed = "onConnectionClosed";
        this.ReceiveNoConnection = "ReceiveNoConnection";
        this.webSocketClosed = "webSocketClosed";
    }
    connection() {
        // this._connection = new WebSocket('wss://indicasino.czargaming.com/ws');
        let myGameId = sessionStorage.getItem("gameId");
        console.log(myGameId);
        let mySiteId = sessionStorage.getItem("siteId");
        console.log(mySiteId)
        let myLang = sessionStorage.getItem("lang");

        if(myLang == null){
            myLang = "en";
        }
        
        let myPlayerToken = sessionStorage.getItem("playerToken");
        // let myPlayerToken = "a1b2";

        // let sendsite="apnakhel"
      //  let sendsite="rrrcasino";
         let sendsite="luckhunter";

        // this._connection = new WebSocket(`wss://gearupcasino.com/ws?gameId=${myGameId}&playerToken=${myPlayerToken}&site=${mySiteId}&lang=${myLang}`);
        // this._connection = new WebSocket(`wss://ballagames.com/ws?gameId=${myGameId}&playerToken=${myPlayerToken}&site=${mySiteId}&lang=${myLang}`);
       this._connection = new WebSocket(`wss://gearupcasino.undertesting.com/ws?gameId=${myGameId}&playerToken=${myPlayerToken}&site=${mySiteId}&lang=${myLang}`);  //Stagings
        // this._connection = new WebSocket(`http://devp4m.czargaming.com/guc/ws?gameId=${myGameId}&playerToken=${myPlayerToken}&site=${mySiteId}&lang=${myLang}`);  //indicasino staging
        // this._connection = new WebSocket(`wss://devp4m.czargaming.com/guc/ws?gameId=${myGameId}&playerToken=${myPlayerToken}&site=${mySiteId}&lang=${myLang}`); //Dev
        //  this._connection = new WebSocket(`ws://10.10.20.60:8987/ws?gameId=${myGameId}&playerToken=${myPlayerToken}&site=${sendsite}&lang=${myLang}`); // Three of Kind 
      // this._connection = new WebSocket(`ws://10.10.20.60:8989/ws?gameId=${myGameId}&playerToken=${myPlayerToken}&site=${mySiteId}&lang=${myLang}`); // sowmya
        // this._connection = new WebSocket(`ws://10.10.20.54:8969/ws?gameId=${myGameId}&playerToken=${myPlayerToken}&site=${mySiteId}&lang=${myLang}`); // arun
        // this._connection = new WebSocket('ws://10.10.20.72:8989/ws?gameId=19&playerToken=nmmn&site=mcasino');
        // this._connection = new WebSocket(`ws://10.10.20.60:8989/ws?gameId=${myGameId}&playerToken=${myPlayerToken}&site=${mySiteId}&lang=${myLang}`);
        // this._connection = new WebSocket('ws://10.10.20.201:8084/ws');
        // this._connection = new WebSocket('ws://10.10.20.72:8989/ws');

        this._connection.addEventListener('open', this.connectEventHandler.bind(this));
        this._connection.addEventListener('close', this.closeEventHandler.bind(this));
        this._connection.addEventListener('error', this.errorEventHandler.bind(this));
        this._connection.addEventListener('message', this.dataEventHandler.bind(this));
    }


    connectEventHandler(event) {
        Emitter.emit(this.ConnectionEstd, event);
    }

    closeEventHandler(event) {
        Emitter.emit(this.webSocketClosed, event);
        console.log(event);
    }
    errorEventHandler(event) {
        Emitter.emit(this.ReceiveNoConnection, event);
    }

    dataEventHandler(event) {
        let data = JSON.parse(event['data']);
        Emitter.emit(this.OnReceivingData, data);
        console.log("Respose from server: ");
        console.log(data);
    }
    sendof(body) {
        let data = JSON.stringify(body);
       
        console.log("Request from Client: ")
        console.log(data);
        // console.log(this._connection.readyState);
        //console.log(this._connection);
        // if(this._connection.readyState === WebSocket.CLOSED || this._connection.readyState == 3 || this._connection.readyState == 0){
        //     console.log( 'WebSocket connection closed' )

        // } 
        if (this._connection.readyState == 1) {
            this._connection.send(data);
        } else {
            console.log('WebSocket connection closed')
        }
    }

}